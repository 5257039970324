import React from "react";
import Layout from "../components/layout/Layout";

function NotFound(props) {
  return (
    <Layout>
      <div className="notfound">
        <img src="/images/404.svg" alt="Sivua ei löytynyt" />
        <div>Etsimääsi sivua ei löytynyt.</div>
      </div>
    </Layout>
  );
}

export default NotFound;
